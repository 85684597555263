$primary: #3D348B;
$secondary: #B4ADEA;
$success: #70A288;
$warning: #EDB458;
$danger: #C3423F;

@import 'bootstrap';
@import 'react-datepicker';

.todo-app-container {
  position: relative;

  .todo-rmv-btn {
    color: $red;
    cursor: pointer;
  }

  .todo-edt-btn {
    color: $blue;
    cursor: pointer;
  }

  .todo-done-item {
    text-decoration: line-through;
  }
}

.login-block-container {
  .email, .password {
    input {
      margin-left: $spacer;
    }
  }

  div.input {
    margin-top: $spacer*0.5;
  }

  form {
    margin-bottom: $spacer;
  }

  .btn {
    color: $white;
    background-color: $primary;
    border-color: $primary;
    margin-top: $spacer*0.5;
  }
}